@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer utilities {
  .hide-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .hide-scrollbar::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari and Opera */
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 1s ease-out;
}

.hover-gradient-bg:hover {
  background: linear-gradient(90deg, #a100ff 0%, #161830);
}

@property --num {
  syntax: '<integer>';
  initial-value: 0;
  inherits: false;
}

.animate-count {
  transition: --num 2s;
  counter-reset: num var(--num);
  animation: counter 2s forwards;
}

.animate-count::after {
  content: counter(num);
}

@keyframes counter {
  from {
    --num: 0;
  }

  to {
    --num: var(--end-value);
  }
}

.bg-custom-gradient-anim {
  background: linear-gradient(90deg, rgba(161, 0, 255, 1), rgba(87, 207, 255, .5));
  background-size: 200% 100%;
  animation: gradientAnimation 5s ease infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@layer utilities {
  .glow {
    box-shadow: 0 0 20px 5px rgba(59, 130, 246, 0.5);
  }
}


.active-gradient-border {
  position: relative;
}

.active-gradient-border::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 75%; /* Adjust the width to control the gradient length */
  height: 2px; /* Same as border-bottom size */
  background: linear-gradient(to right, #a100ff -55%, #a100ff);
}
.dropdown-adjust {
  left: calc(50% - 1.5rem); /* Adjust this value as needed */
}


/* Custom scrollbar styles */
.custom-scrollbar-container {
  position: relative;
  overflow: hidden;
}

.custom-scrollbar-content {
  overflow-y: auto;
  padding-right: 12px;
  box-sizing: content-box;
  height: 100%;
  max-height: 60vh; /* Adjust this value as needed */
}

.custom-scrollbar-track {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 6px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
}

.custom-scrollbar-thumb {
  position: absolute;
  width: 6px;
  background-color: rgba(161, 0, 255, 0.5);
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.custom-scrollbar-thumb:hover {
  background-color: rgba(161, 0, 255, 0.7);
}

/* Hide default scrollbar for webkit browsers */
.custom-scrollbar-content::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* Optional: Hide scrollbar for Firefox */
.custom-scrollbar-content {
  scrollbar-width: none;
}